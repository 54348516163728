html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $mainFont;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  background-color: $white;
  color: $grey;
  overflow-x: hidden;

  @include adpt($bmob-r) {
    font-size: 15px;
  }
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 50px;
  @include adpt($tab-r) {
    padding: 0 20px;
  }
}

.sr-only {
  position: absolute;
  width: 0px;
  height: 0px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.logo {
  max-width: 260px;
  width: 100%;

  &__img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.text-bold {
  font-weight: 700;
  font-size: 20px;
}

.btn {
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 20px;
  border: 1px solid $grey;

  @include adpt($bmob-r) {
    font-size: 15px;
    padding: 7px 20px 5px;
  }

  &_2 {
    background-color: $green;
    transition: box-shadow 0.3s;
    border-color: $green;
    padding: 10px 30px 12px;

    @include adpt($bmob-r) {
      padding: 10px 30px 9px;
    }

    &:hover {
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    }
  }

  &_3 {
    background-color: $yellow;
    transition: box-shadow 0.3s;
    border-color: $yellow;
    padding: 10px 30px 12px;

    @include adpt($bmob-r) {
      padding: 10px 30px 9px;
    }

    &:hover {
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    }
  }

  &_4 {
    background-color: $blue;
    transition: box-shadow 0.3s;
    border-color: $blue;
    color: $white;
    padding: 10px 30px 12px;

    @include adpt($bmob-r) {
      padding: 10px 30px 9px;
    }

    &:hover {
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    }
  }
}

.burger {
  cursor: pointer;
  width: 40px;
  height: 40px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

._fixed {
  overflow: hidden;
}

._h1 {
  font-weight: 700;
  font-size: 74px;
  line-height: 1.2;

  @include adpt($big-r) {
    font-size: 70px;
  }

  @include adpt($tab-r) {
    font-size: 66px;
  }

  @include adpt($mtab-r) {
    font-size: 54px;
  }

  @include adpt($stab-r) {
    font-size: 50px;
  }

  @include adpt($bmob-r) {
    font-size: 44px;
  }

  @include adpt($smob-r) {
    font-size: 40px;
  }
}

._h2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 1.2;

  @include adpt($mtab-r) {
    font-size: 60px;
  }

  @include adpt($stab-r) {
    font-size: 42px;
  }

  @include adpt($bmob-r) {
    font-size: 28px;
  }

  @include adpt($smob-r) {
    font-size: 24px;
  }
}

._h3 {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.2;

  @include adpt($mtab-r) {
    font-size: 40px;
  }

  @include adpt($stab-r) {
    font-size: 32px;
  }

  @include adpt($bmob-r) {
    font-size: 28px;
  }
}

._h4 {
  font-size: 25px;
  font-weight: 700;
}

.news-section {
  margin-bottom: 100px;

  @include adpt($bmob-r) {
    margin-bottom: 50px;
  }
}

p.has-background {
  border-radius: 20px;
  padding: 30px;
  box-shadow: 4px 4px 10px rgba(107, 128, 137, 0.3);

  @include adpt($bmob-r) {
    padding: 20px;
  }
}

.text-bold {
  @include adpt($bmob-r) {
    font-size: 18px;
  }
}
